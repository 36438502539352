import { graphql } from 'gatsby'
import HtmlParser from 'html-react-parser'
import React from 'react'
import BoldText from 'components/BoldText'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'

type Props = {
  data: unknown
}

export default function CareerPosition({ data }: Props) {
  const { absolute_url: absoluteUrl, content: contentEncoded, location, title } = data.greenhouseJob
  useEventPageVisited('Career position', { title })

  function decodeHtml(html: string) {
    if (typeof window === 'undefined') return html

    const textarea = document.createElement('textarea')
    textarea.innerHTML = html
    return textarea.value
  }

  const applyUrl = `${absoluteUrl}#app`
  const content = decodeHtml(contentEncoded)

  return (
    <Layout className="CareerPosition">
      <Head title={title} description={`${title} at Bold`} />
      <View>
        <Container size="xlarge">
          <Row>
            <Title
              nav={
                <Button to={applyUrl} color="yellow">
                  Apply
                </Button>
              }>
              <Row size="xxsmall">
                <Text casing="capitalize" color="blue" size="xlarge">
                  <BoldText />
                </Text>
              </Row>
              <Text copy element="div">
                {title}
                {location && (
                  <>
                    <br />
                    {location.name.replace(', United States', '')}
                  </>
                )}
              </Text>
            </Title>
          </Row>
          <Copy>{HtmlParser(content)}</Copy>
          <Divider />
          <Buttons align="center">
            <Button to={applyUrl} color="yellow">
              Apply
            </Button>
            <Button to={paths.CAREERS} color="white">
              More positions
            </Button>
          </Buttons>
        </Container>
      </View>
    </Layout>
  )
}

export const query = graphql`
  query CareerPosition($id: String!) {
    greenhouseJob(id: { eq: $id }) {
      absolute_url
      content
      location {
        name
      }
      title
    }
  }
`
